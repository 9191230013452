import React, { useState } from "react";
import {
  BoxProps,
  VStack,
  HStack,
  Button,
  IconButton,
  SkeletonText,
  Text,
  Heading,
  useToast,
  Progress,
  Box,
  ButtonGroup,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  useDisclosure,
  Tabs,
  TabList,
  Tab,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Badge,
  Tooltip,
} from "@chakra-ui/react";
import { ExportIcon, RefreshIcon } from "../constants/icons";
import { InviteListCampaign } from "../models";
import { formatDate, getCampaignProgress, capitalize } from "../utils/helpers";
import { exportMessageStatus } from "../api/flow";
import { retryInviteCampaign } from "../api/invite";
import { useAuthToken } from "../hooks/useAuthToken";

interface InviteListCampaignTileProps extends BoxProps {
  campaign: InviteListCampaign;
}

const getUserCount = (campaign: InviteListCampaign) => {
  if (campaign.metadata["count"]) return campaign.metadata["count"];

  let count = 0;
  Object.keys(campaign.summary).map(
    (status) => (count += campaign.summary[status])
  );
  return count;
};

const statuses = [
  { label: "Successful", color: "green", alertType: "success" },
  { label: "In Progress", color: "black", alertType: "info" },
  { label: "Failed", color: "red", alertType: "error" },
  { label: "Other", color: "orange", alertType: "warning" },
]

export const InviteListCampaignTile = ({
  campaign,
  ...props
}: InviteListCampaignTileProps) => {
  const { getAuthToken } = useAuthToken();

  // details modal states
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedStatusIndex, setSelectedStatusIndex] = useState(0);

  const totalUsers = getUserCount(campaign);
  const message = useToast();

  const [exportLoading, setExportLoading] = useState(false);

  const progress = getCampaignProgress(campaign.summary ?? {});

  const getStatusCategoryTotal = (category) => {
    return Object.values(campaign?.summary?.[category] ?? {}).map(x => (x.count ?? 0)).reduce((a, b) => a + b, 0);
  }


  const handleExportFailed = () => {
    setExportLoading(false);
    setTimeout(() => {
      message({
        title: "something went wrong",
        status: "error",
      });
    }, 500);
  };

  const exportCSV = async () => {
    try {
      const a = document.createElement("a");
      a.style.display = "none";
      document.body.appendChild(a);

      setExportLoading(true);
      const token = await getAuthToken();
      const response = await exportMessageStatus({
        token,
        flowId: campaign.id,
      }).catch((_) => {
        handleExportFailed();
      });

      if (response) {
        setExportLoading(false);
        const blobFile = new Blob([response], { type: "text/csv" });
        const url = window.URL.createObjectURL(blobFile);
        a.href = url;
        a.download = `${campaign.name}.csv`;
        a.click();
        return window.URL.revokeObjectURL(url);
      }
    } catch (error) {
      handleExportFailed();
    }
  };

  const retryFailed = async () => {
    try {
      const token = await getAuthToken();

      const response = await retryInviteCampaign({
        flowId: campaign.id,
        token,
      }).catch((_) => {
        handleExportFailed();
      });

      if (response) {
        message({
          title: "retry success",
          status: "success",
        });
      }
    } catch (error) {
      handleExportFailed();
    }
  };

  return (
    <>
      <VStack
        bg="white"
        boxShadow="sm"
        borderWidth="thin"
        borderRadius="md"
        spacing={4}
        p={4}
        transition="box-shadow 100ms ease-out 0s"
        w="100%"
        align="start"
        {...props}
      >
        <HStack w="full" justify="space-between" align="start">
          <Text fontSize="md" fontWeight="medium">
            {campaign.name}
          </Text>
          <ButtonGroup>
            {/* <Button
            tooltip="Retry failed invites"
            onClick={() => retryFailed()}
            leftIcon={<RefreshIcon />}
            disabled={exportLoading}
            isLoading={exportLoading}
            variant="tertiary"
            size="sm"
          >
            Retry
          </Button> */}
            <Button
              size="sm"
              variant="outline"
              colorScheme="pink"
              onClick={onOpen}
              isLoading={campaign.summaryStatus === "Loading"}
              disabled={campaign.summaryStatus === "Loading"}
            >
              Details
            </Button>
            <IconButton
              onClick={() => exportCSV()}
              icon={<ExportIcon />}
              isLoading={campaign.summaryStatus === "Loading" || exportLoading}
              disabled={campaign.summaryStatus === "Loading" || exportLoading}
              variant="outline"
              colorScheme="pink"
              aria-label={""}
              size="sm"
            />
          </ButtonGroup>
        </HStack>
        {campaign.summaryStatus === "Loading" ? (
          <SkeletonText w="full" noOfLines={3} spacing="2" skeletonHeight="3" />
        ) : (
          <>
            <HStack
              w="full"
              justify="space-between"
              align="end"
              hidden={campaign.status === "Processing"}
            >
              <HStack align="start" spacing={8}>
                <VStack spacing={1} align="start">
                  <Text>Total Invites</Text>
                  <Heading size="md">{totalUsers}</Heading>
                </VStack>
                {statuses.map(status => (
                  <VStack spacing={1} align="start">
                    <Text color={status.color}>{status.label}</Text>
                    <Heading size="md">
                      {getStatusCategoryTotal(status.label)}
                    </Heading>
                  </VStack>
                ))}
              </HStack>
              <Text>{formatDate(campaign.timestamp)}</Text>
            </HStack>
            <HStack
              w="full"
              justify="space-between"
              align="end"
              hidden={campaign.status !== "Processing"}
            >
              <VStack spacing={1} align="start">
                <Text>Total Invites</Text>
                <Box pos="relative" width="100%">
                  <Progress
                    borderRadius={8}
                    w="xs"
                    colorScheme="pink"
                    height="32px"
                    value={progress}
                  />
                  <Text pos="absolute" top={1} left={100}>
                    In Progress / {progress}%
                  </Text>
                </Box>
              </VStack>
            </HStack>
          </>
        )}
      </VStack>

      {isOpen && (
        <Modal onClose={onClose} isOpen={isOpen} size="4xl" isCentered>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{campaign.name}</ModalHeader>
            <ModalCloseButton />
            <ModalBody minHeight={500}>
              <Tabs
                index={selectedStatusIndex} 
                onChange={(i) => setSelectedStatusIndex(i)}
              >
                 <TabList>
                  {statuses.map(status => (
                    <Tab>{status.label} ({Object.entries(campaign.summary[status.label]).map(x => x[1].count).reduce((a,b) => a +b, 0)})</Tab>
                  ))}
                </TabList>

                {Object.entries(campaign.summary[statuses[selectedStatusIndex].label]).map(([selectedStatusTitle, selectedStatusObject]) => (
                 <Box my={3}>
                   <Alert alignItems="start" flexDirection='column' borderRadius={3} status={statuses[selectedStatusIndex].alertType as "success" | "warning" | "error" | "info"}>
                    <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
                      <AlertIcon />
                      <AlertTitle>{capitalize(selectedStatusTitle)}</AlertTitle>
                      <Badge variant='subtle' colorScheme={statuses[selectedStatusIndex].color}>{selectedStatusObject.count}</Badge>
                      <Box sx={{ flexGrow: 1 }} />
                      <Tooltip label={selectedStatusObject.is_external ? "This status originated from an external service" : "This status originated from within Workhack"}>
                        <Badge variant='subtle' colorScheme={statuses[selectedStatusIndex].color}>{selectedStatusObject.is_external ? "External" : "Internal"}</Badge>
                      </Tooltip>
                    </Box>
                    <AlertDescription>{selectedStatusObject.description}</AlertDescription>
                  </Alert>
                 </Box>
                ))}
              </Tabs>

            </ModalBody>
            <ModalFooter>
              <Button onClick={onClose}>Close</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};
