import React, { forwardRef, useState, useEffect } from "react";
import { Card, CardProps } from "@tremor/react";
import {
  Box,
  Heading,
  SimpleGrid,
  Stat,
  StatLabel,
  StatNumber,
  Text,
} from "@chakra-ui/react";
import { TopQueries } from "./TopQueries";
import { getFlowStatusBreakdown } from "../../api/flow";
import { useAuthToken } from "../../hooks/useAuthToken";
import { AnalyticsDataLoading } from ".";
import { useTotalQueriesAsked } from "../../data";

interface QuestionMetricsProps extends CardProps {
  flowId: string;
}

interface TotalUsersStatProps extends CardProps {
  flowId: string;
}

const TotalUsersStat = forwardRef<HTMLDivElement, TotalUsersStatProps>(
  ({ flowId, ...props }, ref) => {
    const { user, getAuthToken } = useAuthToken();
    const [totalUsers, setTotalUsers] = useState(0);
    const [loading, setLoading] = useState(true);

    const totalQueriesAsked = useTotalQueriesAsked(flowId);

    useEffect(() => {
      const fetchUserDataAbortController = new AbortController();

      const fetchData = async (controller: AbortController) => {
        const token = await getAuthToken();
        setLoading(true);
        const response = await getFlowStatusBreakdown({
          flowId,
          token,
          filters: [],
          abortController: controller,
        }).catch((_) => {
          setLoading(false);
        });
        setLoading(false);

        if (response) {
          let count = 0;
          Object.keys(response.status).map(
            (status) => (count += response.status[status])
          );
          setTotalUsers(count);
        }
      };

      fetchData(fetchUserDataAbortController);
      return () => {
        fetchUserDataAbortController?.abort();
      };
    }, []);

    if (loading || totalQueriesAsked.isLoading) {
      return <AnalyticsDataLoading title="Total Users" />;
    }

    return (
      <Card ref={ref} {...props}>
        <Stat>
          <Text flexGrow={1} fontWeight={700}>
            Total Users
          </Text>
          <Heading flexGrow={1} fontWeight={700}>
            {Intl.NumberFormat("us").format(totalUsers).toString()}
          </Heading>
        </Stat>
        <Box height={12} />
        <Stat>
          <Text flexGrow={1} fontWeight={700}>
            Total Queries Asked
          </Text>
          <Heading flexGrow={1} fontWeight={700}>
            {Intl.NumberFormat("us")
              .format(totalQueriesAsked?.data?.total_queries_asked ?? 0)
              .toString()}
          </Heading>
        </Stat>
      </Card>
    );
  }
);

export const QuestionMetrics = forwardRef<HTMLDivElement, QuestionMetricsProps>(
  ({ flowId, ...props }, ref) => {
    return (
      <SimpleGrid gridGap={4} templateColumns={"2fr 1fr"}>
        <TopQueries flowId={flowId} />
        <TotalUsersStat flowId={flowId} />
      </SimpleGrid>
    );
  }
);
