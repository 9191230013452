import { useQuery, useQueryClient } from "@tanstack/react-query";
import { EventSourcePolyfill } from "event-source-polyfill";
import { useAuthToken } from "../../hooks/useAuthToken";
import config from "../../config";
import {
  AnalyticsDataFetchError,
  AnalyticsDataFetchFinish,
  AnalyticsDataFetchEvent,
  AnalyticsDataFetchBegin,
} from ".";

export interface MessageDeliveryStat extends AnalyticsDataFetchEvent {
  type: "data";
  Timestamp: string;
  Summary: {
    [status: string]: number;
  };
}

export const useMessageDelivery = (
  flowId: string,
  range: { start_date: number; end_date: number } | string
) => {
  const rangeKey =
    typeof range === "string" ? range : `${range.start_date}-${range.end_date}`;

  const queryKey = ["messageDelivery", flowId, rangeKey];
  const { getAuthToken } = useAuthToken();
  const queryClient = useQueryClient();

  const fetchMessageStats = async () => {
    const dataFetchBegin: AnalyticsDataFetchBegin = {
      type: "start",
    };

    const token = await getAuthToken();

    let url = `${config.url}/v2/analytics/message_delivery_stats/${flowId}`;

    if (typeof range === "string") {
      url = `${url}?campaign_id=${range}`;
    } else {
      url = `${url}?date_range=${JSON.stringify(range)}`;
    }

    const eventSource = new EventSourcePolyfill(url, {
      headers: { Authorization: `Bearer ${token}` },
    });

    eventSource.onopen = () =>
      console.log("[message delivery]: connection opened");

    eventSource.onmessage = (event) => {
      if (event && event.data && event.data.trim().length > 0) {
        try {
          let analyticsEvent:
            | AnalyticsDataFetchError
            | MessageDeliveryStat
            | AnalyticsDataFetchFinish = JSON.parse(event.data);

          if (analyticsEvent.type === "finish") {
            console.log("[]: closing connection as data fetch is finished");
            eventSource.close();
          }

          if (analyticsEvent.type === "finish") {
            console.log(
              "[message delivery]: closing connection as data fetch is finished"
            );
            queryClient.setQueriesData(queryKey, (queryData) => {
              if (queryData) {
                // if query data is not an array, it means that the query data is an error

                return Array.isArray(queryData) ? queryData.reverse() : [];
              }
            });
            eventSource.close();
          }

          if (analyticsEvent.type === "error") {
            // TODO: Handle error
            console.log(analyticsEvent);
          }

          if (analyticsEvent.type === "data") {
            queryClient.setQueriesData(queryKey, (queryData) => {
              if (queryData) {
                if (!Array.isArray(queryData)) {
                  console.log(
                    "[message delivery]: appending first data to query data",
                    queryData
                  );
                  return [analyticsEvent];
                }

                try {
                  let queriesToAdd: MessageDeliveryStat[] = [];
                  let prevQuery: MessageDeliveryStat;
                  const prevQueryData: MessageDeliveryStat[] =
                    queryData as MessageDeliveryStat[];

                  prevQueryData.map((query) => {
                    // if there's an existing data available on timestamp
                    if (
                      query.Timestamp ===
                      (analyticsEvent.type === "data"
                        ? analyticsEvent.Timestamp
                        : "")
                    )
                      prevQuery = query;
                    else queriesToAdd.push(query);
                  });

                  if (prevQuery && analyticsEvent.type === "data") {
                    prevQuery = {
                      ...prevQuery,
                      Summary: {
                        Delivered:
                          (analyticsEvent.Summary.Delivered ?? 0) +
                          (prevQuery.Summary.Delivered ?? 0),
                        Sent:
                          (analyticsEvent.Summary.Sent ?? 0) +
                          (prevQuery.Summary.Sent ?? 0),
                        Failed:
                          (analyticsEvent.Summary.Failed ?? 0) +
                          (prevQuery.Summary.Failed ?? 0),
                      },
                    };
                  }

                  let newQueryData = [...queriesToAdd, prevQuery ?? analyticsEvent];
                  newQueryData = (newQueryData as MessageDeliveryStat[]).sort((a, b) => {
                    if(a.Timestamp.includes("-")) {
                      return (new Date(a.Timestamp).getTime() - new Date(b.Timestamp).getTime());
                    } else {
                      return (parseInt(a.Timestamp) - parseInt(b.Timestamp));
                    }
                  });
                  return newQueryData;

                } catch (error) {
                  console.log(error);
                  return [];
                }
              }
            });
          }
        } catch (error) {
          console.log("[message delivery]: JSON parsing error", error);
        }
      }
    };

    eventSource.onerror = (error) => {
      console.log("[message delivery]: error", error);
      const dataFetchError: AnalyticsDataFetchError = {
        type: "error",
        error: `${error}}`,
      };
      queryClient.setQueriesData(queryKey, dataFetchError);
    };

    return dataFetchBegin;
  };

  return useQuery<
    AnalyticsDataFetchError | AnalyticsDataFetchBegin | MessageDeliveryStat[]
  >(queryKey, fetchMessageStats, {
    keepPreviousData: true,
    staleTime: 1000 * 60 * 30, // 30 mins
  });
};
