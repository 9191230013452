import React from "react";

import { useStoreRehydrated } from "easy-peasy";
import { useAuth0 } from "@auth0/auth0-react";
import { useOutlet } from "react-router-dom";
import { Login } from "../pages/Login";

export const AuthenticatedRoute = () => {
  const { isAuthenticated } = useAuth0();
  const outlet = useOutlet();
  const isStoreLoaded = useStoreRehydrated();

  return isStoreLoaded ? isAuthenticated ? outlet : <Login /> : <div>Loading...</div>;
};
