import axios from "axios";
import { useInfiniteQuery } from "@tanstack/react-query";
import { FilterGroupQuery } from "../../components/Filter/models";
import { SortGroup } from "../../api/users";
import { useAuthToken } from "../../hooks/useAuthToken";
import config from "../../config";
import { SupportConversation } from "../../models/support";

export const useChats = ({
  flowId,
  limit = 20,
  ...request
}: {
  flowId: string;
  filters: FilterGroupQuery;
  sort?: SortGroup[];
  limit?: number;
}) => {
  const { getAuthToken } = useAuthToken();

  const fetchChats = async ({ pageParam = 0 }) => {
    const token = await getAuthToken();

    const response = await axios.post<{
      users: SupportConversation[];
      last_document_index?: number;
    }>(
      `${config.url}/v2/support/${flowId}`,
      {
        filters: request.filters,
        pagination: {
          skip: pageParam,
          size: limit,
        },
        sort: request.sort,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  };

  return useInfiniteQuery(["chats", flowId], fetchChats, {
    // getPreviousPageParam: (firstPage) => firstPage ?? undefined,
    getNextPageParam: (lastPage) => lastPage.last_document_index ?? undefined,
    keepPreviousData: true,
    refetchInterval: 1000 * 2, // 2 seconds
    // refetchIntervalInBackground: false,
    // refetchOnWindowFocus: false,
  });
};
