import React, { createContext, useState } from "react";
import { SimpleGrid, Stack, Container, IconButton } from "@chakra-ui/react";
import { useParams, Outlet, useNavigate } from "react-router-dom";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import { UserMenu } from "../components/UserMenu";
import { useFlowInfo } from "../hooks/useFlowInfo";
import { FlowSwitcher } from "../components/FlowSwitch";
import { colorSystem } from "../theme";
import { appRoutes } from "./routes";
import { AppRoute } from "../components/Navigation/Route";
import { useFeatureFlags } from "../data/flags/useFeatureFlags";
import { DISABLE_IMP_SIDE_MENU } from "../constants/feature_flags";
const { selectedGrey, surfaceGrey, innerShadow } = colorSystem;
export const PageContext = createContext<boolean>(false);
/**
 * @desc This is the main layout for the app.
 *
 * - It contains the sidebar and the main content.
 * - The sidebar contains the flow switcher and the main navigation.
 * - The main content is the outlet for the routes.
 *
 * @returns {JSX.Element}
 */
export const PageLayout = (): JSX.Element => {
	const { id: flowId } = useParams();
	const { data: featureFlags } = useFeatureFlags(flowId);
	const navigate = useNavigate();
	const flowInfo = useFlowInfo(flowId);
	const isFeatureEnabled = (feature: string) => {
		return (featureFlags?.flags ?? []).includes(feature);
	};
	const [isDocked, setIsDocked] = useState<boolean>(false);

	return (
		<PageContext.Provider value={isDocked}>
			<SimpleGrid
				maxW="100vw"
				overflow="hidden"
				templateColumns={`${isDocked ? "5rem" : "15rem"} auto`}
			>
				<IconButton
					aria-label={"dock"}
					size="xs"
					bg={surfaceGrey}
					top={2}
					left={isDocked ? "4rem" : "14rem"}
					position="absolute"
					borderRadius="full"
					boxShadow="0px 0px 1px rgba(9, 30, 66, 0.31), 0px 2px 4px rgba(9, 30, 66, 0.25);"
					zIndex={1000}
					icon={
						isDocked ? (
							<FiChevronRight size={16} />
						) : (
							<FiChevronLeft size={16} />
						)
					}
					onClick={() => setIsDocked((isDock) => !isDock)}
				/>
				<Stack
					h="100vh"
					position="sticky"
					top={0}
					bottom={0}
					as="aside"
					bg="gray.100"
					boxShadow={innerShadow}
					borderRightWidth="thin"
				>
					{/* TODO: Switch Flows */}
					<FlowSwitcher
						flowInfo={flowInfo}
						borderBottomWidth="thin"
						variant={isDocked ? "dock" : "default"}
					/>
					{/* Main Navigation */}
					<Stack p={4} spacing={0} flex={1}>
						{
							// Feature flag is enabled, includes only "users" and "invite"
							appRoutes
								.filter((pageRoute) =>
									isFeatureEnabled(DISABLE_IMP_SIDE_MENU)
										? pageRoute.title === "Users" ||
										  pageRoute.title === "Invite"
										: true
								)
								.map((pageRoute) => (
									<AppRoute
										key={pageRoute.to}
										title={pageRoute.title}
										icon={pageRoute.image}
										route={
											pageRoute.to === ""
												? `/dashboard/${flowId}`
												: `/dashboard/${flowId}${pageRoute.to}`
										}
										variant={isDocked ? "dock" : "default"}
									/>
								))
						}
					</Stack>
					{/* Logout */}
					<UserMenu
						variant={isDocked ? "dock" : "default"}
						borderTopWidth="thin"
						borderColor={selectedGrey}
					/>
				</Stack>
				<Container
					as="main"
					p={0}
					m={0}
					maxW={`calc(100vw - ${isDocked ? "5rem" : "15rem"})`}
					maxH="100vh"
					overflowY="scroll"
				>
					<Outlet />
				</Container>
			</SimpleGrid>
		</PageContext.Provider>
	);
};
