import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { DateRange } from "react-day-picker";
import {
  ButtonGroup,
  SimpleGrid,
  Skeleton,
  Text,
  Stack,
  Stat,
  StatLabel,
  StatNumber,
  Box,
  Progress,
  Flex,
} from "@chakra-ui/react";

import { Appbar } from "../../components/Appbar";
import { ReadReceipts } from "./ReadReceipts";
import { Button } from "../../components/Button";
import { DatePicker } from "../../components/Input";
import { LiveUsers } from "./LiveUsers";
import { MessageDelivery } from "./MessageDelivery";
import { Card } from "@tremor/react";
import { DropOff } from "./DropOff";
import { SelectCampaign } from "../../components/Input/Campaign";
import { HypdLinkClicks } from "./LinkClicks";
import { useCampaignConfig } from "../../data/analytics/useConfig";
import { useFeatureFlags } from "../../data/flags/useFeatureFlags";
import { ReplyRate } from "./ReplyRate";
import {
  DISABLE_LIVE_USERS,
  QUESTION_ANALYTICS,
  DISABLE_CAMPAIGN_PERFORMANCE_METRICS,
} from "../../constants/feature_flags";
import { QuestionMetrics } from "./QuestionMetrics";

export const getTimeRange = (
  timeRange: null | "today" | "week" | "month" | DateRange
) => {
  if (timeRange == null) {
    const now = new Date();
    return {
      start_date: new Date(0).getTime() / 1000,
      end_date: now.getTime() / 1000,
    };
  }

  const getDateBefore = (date: Date, days: number) => {
    const result = new Date(date);
    result.setDate(result.getDate() - days);
    return result;
  };

  const getDateAfter = (date: Date, days: number) => {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  };

  if (timeRange === "today") {
    // Replace with your current date object
    const dayStart = new Date();
    const now = new Date();

    return {
      // from start of day
      start_date: dayStart.setHours(0, 0, 0, 0) / 1000,
      // now
      end_date: now.getTime() / 1000,
    };
  }

  if (timeRange === "week") {
    // Replace with your current date object
    const currentDate = new Date();
    const now = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate()
    );
    const previousDate = getDateBefore(now, 7);
    const nextDate = getDateAfter(now, 1);

    return {
      // start of week
      start_date: previousDate.getTime() / 1000,
      // end of the week
      end_date: nextDate.getTime() / 1000,
    };
  }

  if (timeRange === "month") {
    // Replace with your current date object
    const currentDate = new Date();
    const now = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate()
    );
    const previousDate = getDateBefore(now, 30);
    const nextDate = getDateAfter(now, 1);

    return {
      // start of week
      start_date: previousDate.getTime() / 1000,
      // end of the week
      end_date: nextDate.getTime() / 1000,
    };
  }

  return {
    start_date: timeRange.from.getTime() / 1000,
    end_date: (timeRange.to?.getTime() ?? Date.now()) / 1000,
  };
};

export const formatDate = (timestamp: string | number) => {
  if (typeof timestamp === "number") {
    try {
      const date = new Date(timestamp * 1000);
      return date.toLocaleDateString("en-US", {
        month: "short",
        day: "numeric",
      });
    } catch (error) {
      return "";
    }
  }

  try {
    if (timestamp.includes("-")) {
      const date = new Date(timestamp);
      return date.toLocaleDateString("en-US", {
        month: "short",
        day: "numeric",
      });
    }

    return timestamp;
  } catch (error) {
    return "";
  }
};

interface AnalyticsLoadingProps extends React.HTMLAttributes<HTMLDivElement> {
  title: string;
  count?: number;
}

interface AnalyticsDataFailedProps
  extends React.HTMLAttributes<HTMLDivElement> {
  title: string;
  errorMessage: string;
  retryText?: string;
  onRetry?: () => void;
}

let progressUpdateTimers = {};
export const AnalyticsDataLoading = ({
  title,
  count = 2000,
  ...props
}: AnalyticsLoadingProps) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (progressUpdateTimers[title]) {
      clearInterval(progressUpdateTimers[title]);
      delete progressUpdateTimers[title];
    }

    progressUpdateTimers[title] = setInterval(() => {
      setProgress((currentProgress) => {
        let progressIncrement = 0.8;
        if (currentProgress > 80) {
          progressIncrement = (100 - currentProgress) / 500;
        } else if (currentProgress > 60) {
          progressIncrement = (100 - currentProgress) / 250;
        } else if (currentProgress > 40) {
          progressIncrement = 0.4;
        }
        return currentProgress + progressIncrement;
      });
    }, 50);

    return () => {
      if (progressUpdateTimers[title]) {
        clearInterval(progressUpdateTimers[title]);
        delete progressUpdateTimers[title];
      }
    };
  }, []);

  return (
    <Flex overflow="hidden" p="1px" borderRadius="0.5rem">
      <Card {...props}>
        <Progress
          colorScheme="pink"
          size="xs"
          isIndeterminate={false}
          value={progress}
          isAnimated
          mt={-6}
          mx={-6}
          mb={6}
          w="-webkit-fill-available"
        />
        <Stat>
          <StatLabel>{title}</StatLabel>
          <Skeleton w="min-content">
            <StatNumber>{count}</StatNumber>
          </Skeleton>
        </Stat>
        <Skeleton className="h-48 mt-4" />
      </Card>
    </Flex>
  );
};

export const AnalyticsDataFailed = ({
  title,
  errorMessage,
  retryText = "Retry",
  onRetry,
  ...props
}: AnalyticsDataFailedProps) => {
  return (
    <Card {...props}>
      <Stat>
        <StatLabel>{title}</StatLabel>
        <StatNumber>-</StatNumber>
      </Stat>
      <Stack className="h-48 mt-4 border-dashed border justify-center items-center">
        <Text>{errorMessage}</Text>
        {onRetry != null && (
          <Button variant="tertiary" onClick={() => onRetry?.()}>
            {retryText}
          </Button>
        )}
      </Stack>
    </Card>
  );
};

const DateFilter = ({
  isActive = true,
  timeRangeFilter,
  setTimeRangeFilter,
}) => {
  return (
    <ButtonGroup size="sm" colorScheme="pink" alignItems="center" isAttached>
      <Button
        variant="secondary"
        onClick={() => setTimeRangeFilter("today")}
        isActive={isActive && timeRangeFilter == "today"}
      >
        Today
      </Button>
      <Button
        variant="secondary"
        onClick={() => setTimeRangeFilter("week")}
        isActive={isActive && timeRangeFilter == "week"}
      >
        Last 7 Days
      </Button>
      <Button
        variant="secondary"
        onClick={() => setTimeRangeFilter("month")}
        borderRightRadius={0}
        isActive={isActive && timeRangeFilter == "month"}
      >
        Last 30 Days
      </Button>
      <DatePicker
        variant={
          isActive &&
          timeRangeFilter != "today" &&
          timeRangeFilter != "week" &&
          timeRangeFilter != "month"
            ? "primary"
            : "secondary"
        }
        title="Custom"
        date={typeof timeRangeFilter !== "string" ? timeRangeFilter : undefined}
        onDateClear={() => setTimeRangeFilter("week")}
        onDateSelected={(dateRange) => setTimeRangeFilter(dateRange)}
        style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
      />
    </ButtonGroup>
  );
};

type AnalyticsDateRange = null | "today" | "week" | "month" | DateRange;

export const HypdAnalytics = () => {
  const { botId, id } = useParams();
  const navigate = useNavigate();
  const flowId = botId ?? id;

  const { isLoading, data } = useCampaignConfig(flowId);
  let campaignNameToIdMap = {};
  if (Array.isArray(data)) {
    data.map((c) => (campaignNameToIdMap[c.name] = c.campaign_id));
  }

  const [campaignFilter1, setCampaignFilter1] = useState<string>("");
  const [campaignFilter2, setCampaignFilter2] = useState<string>("");
  const { data: featureFlags } = useFeatureFlags(flowId);

  const [globalTimeRangeFilter, setGlobalTimeRangeFilter] =
    useState<AnalyticsDateRange>("week");

  const [campaignTimeRangeFilter, setCampaignTimeRangeFilter] =
    useState<AnalyticsDateRange>("week");

  useEffect(() => {
    if (["arvind-corp", "ARVIND_RETAIL"].includes(flowId)) {
      navigate(`/dashboard/${flowId}/analytics/dhwani`, { replace: true });
    }
  }, [flowId]);

  useEffect(() => {
    if (campaignTimeRangeFilter != null) {
      setCampaignFilter1("");
    }
  }, [campaignTimeRangeFilter]);

  useEffect(() => {
    if (campaignFilter1 != "") {
      setCampaignTimeRangeFilter(null);
    }
  }, [campaignFilter1]);

  const effectiveCampaignFilter1 = useMemo(() => {
    return campaignFilter1 !== ""
      ? campaignNameToIdMap[campaignFilter1]
      : getTimeRange(campaignTimeRangeFilter);
  }, [campaignFilter1, campaignTimeRangeFilter]);

  const effectiveCampaignFilter2 = useMemo(
    () => campaignNameToIdMap[campaignFilter2],
    [campaignFilter2]
  );

  const effectiveGlobalFilter = useMemo(() => {
    return getTimeRange(globalTimeRangeFilter);
  }, [globalTimeRangeFilter]);

  const isFeatureEnabled = (feature: string) => {
    return (featureFlags?.flags ?? []).includes(feature);
  };

  return (
    <Stack className="max-h-screen overflow-y-scroll">
      <Appbar
        variant="sticky"
        title="Analytics"
        px={4}
        borderBottomWidth="thin"
        boxShadow="sm"
      />

      <Stack py={2} px={4}>
        {isFeatureEnabled(QUESTION_ANALYTICS) && (
          <QuestionMetrics flowId={flowId} />
        )}
      </Stack>
      <Stack py={2} px={4}>
        <Card>
          <Box display="flex" gap={3}>
            <Text flexGrow={1} fontWeight={700}>
              Message Delivery Metrics
            </Text>
            <SelectCampaign
              flowId={flowId}
              isActive={campaignFilter1 !== ""}
              selectedCampaign={campaignFilter1}
              setSelectedCampaign={setCampaignFilter1}
            />
            <DateFilter
              isActive={campaignFilter1 == ""}
              timeRangeFilter={campaignTimeRangeFilter}
              setTimeRangeFilter={setCampaignTimeRangeFilter}
            />
          </Box>
          <SimpleGrid
            py={4}
            templateColumns="repeat(3, minmax(0,1fr))"
            gridGap={4}
          >
            {isFeatureEnabled(DISABLE_LIVE_USERS) ? (
              <></>
            ) : (
              <LiveUsers flowId={flowId} range={effectiveCampaignFilter1} />
            )}
            <MessageDelivery flowId={flowId} range={effectiveCampaignFilter1} />
            <ReadReceipts flowId={flowId} range={effectiveCampaignFilter1} />
          </SimpleGrid>
        </Card>
      </Stack>

      {isFeatureEnabled(DISABLE_CAMPAIGN_PERFORMANCE_METRICS) ? (
        <></>
      ) : (
        <Stack py={2} px={4}>
          <Card>
            <Box display="flex" flexGrow={1}>
              <Text flexGrow={1} fontWeight={700}>
                Campaign Performance Metrics
              </Text>
              <DateFilter
                timeRangeFilter={globalTimeRangeFilter}
                setTimeRangeFilter={setGlobalTimeRangeFilter}
              />
            </Box>
            <SimpleGrid
              py={4}
              templateColumns="repeat(3, minmax(0,1fr))"
              gridGap={4}
            >
              <DropOff flowId={flowId} dateRange={effectiveGlobalFilter} />
              {flowId.toLowerCase().includes("hypd") && (
                <HypdLinkClicks flowId={flowId} range={effectiveGlobalFilter} />
              )}
              {/* <PlaceholderGraph title="Reply Rate" description="Under construction" /> */}
            </SimpleGrid>
          </Card>
        </Stack>
      )}

      {isFeatureEnabled(DISABLE_CAMPAIGN_PERFORMANCE_METRICS) ? (
        <></>
      ) : (
        <Stack py={2} px={4}>
          <Card>
            <Box display="flex" gap={3}>
              <Text flexGrow={1} fontWeight={700}>
                Campaign Performance Metrics
              </Text>
              <SelectCampaign
                flowId={flowId}
                isActive={campaignFilter2 !== ""}
                selectedCampaign={campaignFilter2}
                setSelectedCampaign={setCampaignFilter2}
              />
            </Box>
            <SimpleGrid
              py={4}
              templateColumns="repeat(3, minmax(0,1fr))"
              gridGap={4}
            >
              <ReplyRate
                flowId={flowId}
                campaignFilter={effectiveCampaignFilter2}
              />
            </SimpleGrid>
          </Card>
        </Stack>
      )}
    </Stack>
  );
};
