import React, { useState } from "react";
import {
  VStack,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  FormControl,
  Input,
  FormErrorMessage,
  Stack,
  ButtonGroup,
  useToast,
  Heading,
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
} from "@chakra-ui/react";
import { Formik, Field } from "formik";
import PhoneInput from "react-phone-input-2";
import * as Yup from "yup";
import "react-phone-input-2/lib/high-res.css";

import { Button } from "../components/Button";
import { DashboardTemplate } from "../models/property";
import { useStoreActions, useStoreState } from "../hooks/store";
import { ExportIcon } from "../constants/icons";
import { CSVInput } from "../components/Input/Csv";
import { isValidPhoneNumber } from "../utils/countryCodes";
import { generateCSVExport, generateCSVMapping } from "../utils";
import { useUploadFile } from "../mutations/root/useUploadFile";
import { useFeatureFlags } from "../data/flags/useFeatureFlags";
import {
  DISABLE_INTERNATIONAL_NO_IMPORT,
  TEST_ASSIGNMENT_FROM_CSV,
} from "../constants";

type SelectUsersProps = {
  flowId: string;
  template?: DashboardTemplate;
  onNext?: () => void;
  onPrevious?: () => void;
  onBulkImport?: (users: any[]) => void;
};

const UserInviteRequestSchema = Yup.object().shape({
  name: Yup.string().max(1000, "Too Long!"),
  phone: Yup.string().required("phone number is required"),
});

export const SelectNewUsers = ({
  flowId,
  onBulkImport,
  ...props
}: SelectUsersProps) => {
  const invitedUsers = useStoreState((state) => state.newUsers);
  const storeInvitedUsers = useStoreActions((actions) => actions.cacheNewUsers);
  const removeCachedUsers = useStoreActions((actions) => actions.clearNewUsers);
  const storeCampaignData = useStoreActions(
    (actions) => actions.cacheCampaignData
  );
  const toastMessage = useToast();
  const uploadFileMutation = useUploadFile();

  const [users, setUsers] = useState(invitedUsers ?? []);
  const [invalidPhoneNumbers, setInvalidPhoneNumbers] = useState([]);
  const { data: flags } = useFeatureFlags(flowId);

  const isFeatureEnabled = (feature: string) => {
    return (flags?.flags ?? []).includes(feature);
  };

  const uploadCSVFile = async (file?: File) => {
    if (!file) return;

    let response = await uploadFileMutation.mutateAsync(file);

    if (response)
      storeCampaignData({
        key: "csv",
        value: response.url,
      });
  };

  const storeSingleUser = (name: string, phone: string) => {
    storeInvitedUsers([{ Name: name, "Phone Number": phone }]);
    setUsers([{ Name: name, "Phone Number": phone }]);
    toastMessage({
      status: "success",
      description: `saved user`,
      position: "bottom",
    });
  };

  const getInvalidPhoneNumbers = (users: any[]) => {
    let headers = generateCSVMapping(users);
    const phoneKey = headers["Phonenumber"];

    if (!phoneKey) return { invalidPhoneNumbers: [], validPhoneNumbers: [] };

    let invalidPhoneNumbers = [];
    let validPhoneNumbers = [];

    users.forEach((user) => {
      if (isValidPhoneNumber(user[phoneKey] ?? ""))
        validPhoneNumbers.push(user);
      else invalidPhoneNumbers.push(user);
    });

    return { invalidPhoneNumbers, validPhoneNumbers };
  };

  return (
    <Stack justify="center" alignItems="start">
      <Heading className="text-left">who?</Heading>
      <p className="text-left text-xl">which users do you want to ping</p>
      <main className="mt-8 w-full rounded-lg">
        <Tabs colorScheme="pink" variant="enclosed" isLazy>
          <TabList>
            <Tab onClick={() => removeCachedUsers()}>Bulk</Tab>
            <Tab onClick={() => removeCachedUsers()}>Single</Tab>
          </TabList>

          <TabPanels className="w-full ">
            <TabPanel as={VStack} className="w-full" px={24} py={6}>
              <Button
                leftIcon={<ExportIcon />}
                variant="tertiary"
                onClick={() => {
                  isFeatureEnabled(TEST_ASSIGNMENT_FROM_CSV)
                    ? window.open("/assets/sample_test_id.csv", "_blank")
                    : window.open("/assets/sample.csv", "_blank");
                }}
              >
                Download sample file
              </Button>
              <CSVInput
                className="w-full rounded-xl"
                onChange={(file) => uploadCSVFile(file)}
                onParse={(data, clearFile) => {
                  if (data.length === 0) {
                    onBulkImport?.([]);
                    storeInvitedUsers([]);
                    setInvalidPhoneNumbers([]);
                    return setUsers([]);
                  }

                  if (isFeatureEnabled(DISABLE_INTERNATIONAL_NO_IMPORT)) {
                    onBulkImport?.(data);
                    storeInvitedUsers(data);
                    return setUsers(data);
                  }

                  const { invalidPhoneNumbers, validPhoneNumbers } =
                    getInvalidPhoneNumbers(data);

                  setInvalidPhoneNumbers(invalidPhoneNumbers);

                  if (validPhoneNumbers.length > 0) {
                    onBulkImport?.(validPhoneNumbers);
                    storeInvitedUsers(validPhoneNumbers);
                    setUsers(validPhoneNumbers);
                  } else {
                    toastMessage({
                      status: "error",
                      title: "No valid phone numbers found",
                      description:
                        "Try including country code in the phone number",
                      position: "bottom",
                    });
                    onBulkImport?.([]);
                    storeInvitedUsers([]);
                    setUsers([]);
                    clearFile?.();
                  }
                }}
              />
              {invalidPhoneNumbers.length > 0 && (
                <Alert
                  status="error"
                  variant="subtle"
                  flexDirection="row"
                  alignItems="self-start"
                  py={4}
                >
                  <AlertIcon mr={4} />
                  <Stack spacing={1}>
                    <AlertTitle>
                      Invalid phone numbers found in the file
                    </AlertTitle>
                    <AlertDescription>
                      There are {invalidPhoneNumbers.length} phone number(s)
                      with no country code. Please include country code in the
                      phone number and try again.
                    </AlertDescription>
                    <p className="py-1"></p>
                    <Button
                      alignSelf="start"
                      variant="danger"
                      action="delete"
                      onClick={() =>
                        generateCSVExport({
                          records: invalidPhoneNumbers,
                          filename: "invalid_phone_numbers.csv",
                        })
                      }
                    >
                      Export
                    </Button>
                  </Stack>
                </Alert>
              )}
            </TabPanel>
            <TabPanel as={VStack} className="w-full" px={24} py={6}>
              <Formik
                initialValues={{ name: "", phone: "" }}
                onSubmit={async (values, { resetForm }) => {
                  storeSingleUser(values.name, values.phone);
                  onBulkImport?.([]);
                }}
                validationSchema={UserInviteRequestSchema}
              >
                {({ handleSubmit, isSubmitting, resetForm }) => (
                  <Stack w="full" spacing={4}>
                    <Field name="name">
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={form.errors.name && form.touched.name}
                        >
                          <Input
                            {...field}
                            placeholder="Name"
                            maxLength={1000}
                            minLength={5}
                          />
                          <FormErrorMessage>
                            {form.errors.name}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                    <Field name="phone">
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={form.errors.phone && form.touched.phone}
                        >
                          <PhoneInput
                            // {...field}
                            placeholder="Phonenumber"
                            country={"in"}
                            inputStyle={{
                              width: "100%",
                              borderColor: "var(--chakra-colors-gray-200)",
                              backgroundColor: "#F8F9F9",
                              height: "var(--chakra-sizes-10)",
                            }}
                            onChange={(value, data, event, format) => {
                              form.setFieldValue("phone", value);
                            }}
                          />
                          <FormErrorMessage>
                            {form.errors.phone}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                    <ButtonGroup>
                      <Button
                        onClick={() => {
                          storeInvitedUsers(users);
                          handleSubmit();
                        }}
                        isDisabled={isSubmitting}
                      >
                        Save
                      </Button>
                      <Button onClick={() => resetForm()} variant="tertiary">
                        Cancel
                      </Button>
                    </ButtonGroup>
                  </Stack>
                )}
              </Formik>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </main>
    </Stack>
  );
};
