import axios from "axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { useAuthToken } from "../../hooks/useAuthToken";
import { useToast } from "@chakra-ui/react";
import config from "../../config";

interface UpdateUserAttributesProps {
  // query key to refetch after mutation is completed
  refetchKey: string[];

  // flow id
  flowId: string;

  // conversation id
  conversationId: string;

  //   data
  data: { [attributeKey: string]: any };

  // message to show on success
  mutationSuccessMessage?: string;

  // message to show on error
  mutationErrorMessage?: string;

  // on success callback
  onSuccess?: (data: any) => void;
}

export const useUpdateUserAttributes = ({
  flowId,
  conversationId,
  refetchKey,
  data,
  mutationErrorMessage = "failed to update user",
  mutationSuccessMessage = "User data updated successfully",
  onSuccess,
}: UpdateUserAttributesProps) => {
  const updateSuccessToast = "update-success-toast";
  const updateFailedToast = "update-failed-toast";

  const toast = useToast();
  const { getAuthToken } = useAuthToken();
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ["update", ...refetchKey],
    mutationFn: async () => {
      const token = await getAuthToken();

      const response = await axios.post<{
        status: "success";
        data: "User data updated successfully";
      }>(`${config.url}/api/v1/user/${flowId}/${conversationId}`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response && response.data) {
        if (!toast.isActive(updateSuccessToast))
          toast({
            id: updateSuccessToast,
            title: mutationSuccessMessage,
            status: "success",
          });

        onSuccess?.(response.data);
      }

      return response.data;
    },
    // If the mutation fails,
    // use the context returned from onMutate to roll back
    onError: (err, variables, context) => {
      console.log(err, variables, context);
      if (!toast.isActive(updateFailedToast))
        toast({
          id: updateFailedToast,
          description: mutationErrorMessage,
          status: "error",
        });
    },

    // Always refetch after error or success:
    onSettled: () => {
      if (refetchKey.length !== 0)
        queryClient.invalidateQueries({
          queryKey: refetchKey,
        });
    },
  });
};
