const gradients = [
  "linear-gradient(240deg, rgb(111, 156, 190) 22%, rgb(135, 24, 177) 98%)",
  "linear-gradient(211deg, rgb(31, 194, 211) 11%, rgb(3, 139, 241) 99%);",
  "linear-gradient(180deg, #F44D5E 0%, #D92A93 100%)",
  "linear-gradient(180deg, #456ADD 0%, #8651CC 100%);",
  "linear-gradient(229.14deg, #94F533 -2.89%, #2AD0CA 84.74%);",
  "linear-gradient(152.26deg, #F74545 0%, #E1F664 101.41%);",
  "linear-gradient(152.26deg, #B353FF 0%, #F75D8B 101.41%);",
  "linear-gradient(180deg, #FF7784 0%, #AD8173 100%);",
  "linear-gradient(225deg, #4158D0 0%, #C850C0 46.88%, #FFCC70 100%);",
];

export const generateRandom = (min: number = 1, max: number = 16) => {
  // find diff
  let difference = max - min;

  // generate random number
  let rand = Math.random();

  // multiply with difference
  rand = Math.floor(rand * difference);

  // add with min value
  rand = rand + min;

  return rand;
};

const getHashCode = (str: string) => {
  let hash = 0;
  if (str.length === 0) return hash;

  for (let i = 0; i < str.length; i++)
    hash = (Math.imul(31, hash) + str.charCodeAt(i)) | 0;

  return hash;
};

export const getRandomAvatar = (name: string = "") => {
  let index: number = 0;

  if (name) {
    index = Math.abs(getHashCode(name) % 15);
    index = index === 0 ? 6 : index;
  } else {
    index = generateRandom();
  }

  return `https://engine.workhack.io/static/agents/${index}.png`;
};

export const getRandomGradient = (name: string) => {
  if (name.length === 0) return gradients[0];

  const index = Math.abs(getHashCode(name) % (gradients.length - 1));
  return gradients[index];
};

export const getUserInfo = (userMail: string) => {
  const recruiter =
    localStorage.getItem("recruiter") ??
    `{"fields": {"company": "Loading..."}}`;

  const {
    fields: { company },
  } = JSON.parse(recruiter);

  return company ?? userMail;
};
