import axios from "axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useToast } from "@chakra-ui/react";

import { useAuthToken } from "../../hooks/useAuthToken";
import config from "../../config";

export const useUploadFile = (
  success?: string,
  error?: string,
  refetchKey?: string[]
) => {
  const toast = useToast();
  const queryClient = useQueryClient();
  const { getAuthToken } = useAuthToken();

  return useMutation({
    mutationKey: ["new", "file"],
    mutationFn: async (file: File) => {
      const token = await getAuthToken();

      let formData = new FormData();
      formData.append("fileb", file);

      let response = await axios.post<{
        url: string;
        key: string;
        bucket: string;
      }>(`${config.url}/upload_file`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + token,
        },
      });

      if (response.status !== 201)
        return Promise.reject(`failed to upload file`);

      if (response.data && response.data.url) {
        if (success) {
          toast({ title: success, status: "success" });
        }
      }

      return response.data;
    },
    // If the mutation fails,
    // use the context returned from onMutate to roll back
    onError: (err, variables, context) => {
      toast({
        description: error,
        status: "error",
      });
    },

    // Always refetch after error or success:
    onSettled: () => {
      if (refetchKey)
        queryClient.invalidateQueries({
          queryKey: refetchKey,
        });
    },
  });
};
