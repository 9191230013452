import { User, useAuth0 } from "@auth0/auth0-react";

interface AuthToken {
  user?: User;
  getAuthToken: () => Promise<string | undefined>;
}

export const useAuthToken = (
  apiAudience: string = "https://dashboard-api.workhack.io",
  scope: string = "read:test"
): AuthToken => {
  const { user, getAccessTokenSilently } = useAuth0();

  const fetchToken = async () => {
    try {
      const accessToken = await getAccessTokenSilently({
        audience: apiAudience,
        scope: scope,
      });

      return accessToken;
    } catch (error) {}
  };

  return { user, getAuthToken: fetchToken };
};
