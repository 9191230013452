/**
 * Renders Default Conversation of the Flow
 */

import React, { useEffect, useState } from "react";
import { Stack, useToast } from "@chakra-ui/react";
import {
  editDefaultConversation,
  getDefaultConversation,
} from "../../api/engine";
import { useParams } from "react-router-dom";
import { FlowMessage } from "../../components/Conversation/FlowMessage";
import chatImage from "../../img/chat_bg.png";
import NotFound from "../../pages/NotFound";
import { useAuthToken } from "../../hooks/useAuthToken";
import Progress from "../../components/Feedback/Progress";

export const DefaultFlowConversation = () => {
  const { id: flowId } = useParams();
  const toastMessage = useToast();
  const { getAuthToken } = useAuthToken();

  const [loading, setLoading] = useState(false);
  const [flow, setFlow] = useState<Flow | null>({ steps: {} });
  const [currentEditingStep, setCurrentEditingStep] = useState<string>("");

  useEffect(() => {
    getDefaultFlow();
  }, []);

  const getDefaultFlow = async () => {
    const handleErrorResponse = () => {
      setLoading(false);
      toastMessage({
        description: "failed to fetch default conversation",
        status: "error",
      });
    };

    try {
      setLoading(true);
      const token = await getAuthToken();

      const flow = await getDefaultConversation({
        flowId,
        token,
      }).catch((_) => handleErrorResponse());
      setLoading(false);

      if (flow) setFlow(flow);
    } catch (error) {
      handleErrorResponse();
    }
  };

  const updateFlowCopy = async (key: string, value: string) => {
    if (key === "" || value === "") return;

    try {
      setLoading(true);
      const token = await getAuthToken();
      const result = await editDefaultConversation({
        flowId,
        key,
        value,
        token,
      });
      setLoading(false);

      if (result) {
        getDefaultFlow();
        toastMessage({ description: "updated the copy" });
      }
    } catch (error) {
      setLoading(false);
      toastMessage({ description: "failed to update copy", status: "error" });
    }
  };

  return (
    <Stack justify="center" align="center" maxH="100vh">
      <Stack
        w="container.lg"
        boxShadow="0px 4px 13px rgba(0, 0, 0, 0.07);"
        spacing={4}
        p={8}
        h="100vh"
        overflowY="scroll"
        style={{ background: `url(${chatImage}) #FAFBFC` }}
      >
        {loading && <Progress w="full" size="xs" />}
        <Stack hidden={Object.keys(flow.steps).length !== 0}>
          <NotFound title="No Conversation" subtitle="" />
        </Stack>
        {Object.keys(flow.steps).map((stepName, idx) => (
          <FlowStep
            key={idx}
            name={stepName}
            step={flow.steps[stepName]}
            isActive={currentEditingStep === stepName}
            onEdit={() => setCurrentEditingStep(stepName)}
            onChange={(key, value) => updateFlowCopy(key, value)}
          />
        ))}
      </Stack>
    </Stack>
  );
};

interface FlowStepProps {
  name: string; // name of the step
  step: FlowIntentUnion;
  isActive?: boolean;
  onChange?: (key: string, value: string) => void;
  onEdit?: () => void;
}

const FlowStep = ({
  name,
  step,
  isActive = false,
  ...props
}: FlowStepProps) => {
  const [currentActiveIndex, setCurrentActiveIndex] = useState(-1);

  const isEditing = (index: number) => isActive && currentActiveIndex == index;

  const handleEditing = (index: number) => {
    setCurrentActiveIndex(index);
    props.onEdit?.();
  };

  if (step.intent_type === "ask-definite") {
    if (!step.question) return <></>;

    if (typeof step.question === "string")
      return (
        <FlowMessage
          isCurrentlyEditing={isEditing(0)}
          message={step.question}
          onEdit={() => handleEditing(0)}
          onSave={(key, message) => {
            setCurrentActiveIndex(-1);
            props.onChange?.(`${name}.question${key}`, message);
          }}
        />
      );

    return (
      <Stack>
        {step.question.map((stepMessageUnion, index) => (
          <FlowMessage
            key={index}
            isCurrentlyEditing={isEditing(index)}
            message={stepMessageUnion}
            onEdit={() => handleEditing(index)}
            onSave={(key, message) => {
              setCurrentActiveIndex(-1);
              props.onChange?.(`${name}.question.${index}${key}`, message);
            }}
          />
        ))}
      </Stack>
    );
  }

  if (
    step.intent_type === "say" ||
    step.intent_type === "collect-attachments"
  ) {
    if (typeof step.text === "string")
      return (
        <FlowMessage
          isCurrentlyEditing={isEditing(0)}
          message={step.text}
          onEdit={() => handleEditing(0)}
          onSave={(key, message) => {
            setCurrentActiveIndex(-1);
            props.onChange?.(`${name}.text${key}`, message);
          }}
        />
      );

    return (
      <Stack>
        {step.text.map((stepMessageUnion, index) => (
          <FlowMessage
            key={index}
            isCurrentlyEditing={isEditing(index)}
            message={stepMessageUnion}
            onEdit={() => handleEditing(index)}
            onSave={(key, message) => {
              setCurrentActiveIndex(-1);
              props.onChange?.(`${name}.text.${index}${key}`, message);
            }}
          />
        ))}
      </Stack>
    );
  }

  if (step.intent_type === "ask-open" || step.intent_type === "gpt-generate") {
    if (typeof step.template === "string") {
      return (
        <FlowMessage
          isCurrentlyEditing={isEditing(0)}
          message={step.template}
          onEdit={() => handleEditing(0)}
          onSave={(key, message) => {
            setCurrentActiveIndex(-1);
          }}
        />
      );
    }

    return step.template.pre_prompt ? (
      <FlowMessage
        isCurrentlyEditing={isEditing(0)}
        message={step.template.pre_prompt}
        onEdit={() => handleEditing(0)}
        onSave={(key, message) => {
          setCurrentActiveIndex(-1);
          props.onChange?.(`${name}.template.pre_prompt`, message);
        }}
      />
    ) : (
      <></>
    );
  }

  return <></>;
};
