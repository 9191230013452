import { useQuery, useQueryClient } from "@tanstack/react-query";
import { EventSourcePolyfill } from "event-source-polyfill";
import { useAuthToken } from "../../hooks/useAuthToken";
import config from "../../config";
import {
  AnalyticsDataFetchError,
  AnalyticsDataFetchFinish,
  AnalyticsDataFetchEvent,
  AnalyticsDataFetchBegin,
} from ".";

export interface LinkClickStat extends AnalyticsDataFetchEvent {
  type: "data";
  Timestamp: string;
  ClickCount: number;
}

export const useHypdLinkClicks = (
  flowId: string,
  range: { start_date: number; end_date: number } | string
) => {
  const rangeKey =
    typeof range === "string" ? range : `${range.start_date}-${range.end_date}`;

  const queryKey = ["hypdLinkClicks", rangeKey];
  const { getAuthToken } = useAuthToken();
  const queryClient = useQueryClient();

  const fetchLinkClicks = async () => {
    const dataFetchBegin: AnalyticsDataFetchBegin = {
      type: "start",
    };

    const token = await getAuthToken();

    const eventSource = new EventSourcePolyfill(
      `${config.url}/v2/analytics/hypd_link_clicks/${flowId}?date_range=${JSON.stringify(
        range
      )}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    eventSource.onopen = () => console.log("[link clicks]: connection opened");

    eventSource.onmessage = (event) => {
      if (event && event.data && event.data.trim().length > 0) {
        try {
          let analyticsEvent:
            | AnalyticsDataFetchError
            | LinkClickStat
            | AnalyticsDataFetchFinish = JSON.parse(event.data);

          if (analyticsEvent.type === "finish") {
            console.log(
              "[link clicks]: closing connection as data fetch is finished"
            );

            queryClient.setQueriesData(queryKey, (queryData) => {
              if (queryData) {
                // if query data is not an array, it means that the query data is an error
                if (Array.isArray(queryData) === false) return [];

                return queryData;
              }
            });
            eventSource.close();
          }

          if (analyticsEvent.type === "error") {
            // TODO: Handle error
            console.log(analyticsEvent);
          }

          if (analyticsEvent.type === "data") {
            queryClient.setQueriesData(queryKey, (queryData) => {
              if (queryData) {
                if (!Array.isArray(queryData)) {
                  console.log(
                    "[link clicks]: appending first data to query data",
                    queryData
                  );
                  return [analyticsEvent];
                }

                try {
                  const prevQuery: LinkClickStat[] =
                    queryData as LinkClickStat[];

                  let newQueryData = [
                    ...prevQuery.filter(
                      (_) =>
                        _.Timestamp !==
                        (analyticsEvent.type === "data"
                          ? analyticsEvent.Timestamp
                          : "")
                    ),
                    analyticsEvent,
                  ];
                  newQueryData = (newQueryData as LinkClickStat[]).sort((a, b) => {
                    if(a.Timestamp.includes("-")) {
                      return (new Date(a.Timestamp).getTime() - new Date(b.Timestamp).getTime());
                    } else {
                      return (parseInt(a.Timestamp) - parseInt(b.Timestamp));
                    }
                  });
                  return newQueryData;

                } catch (error) {
                  console.log(error);
                  return [];
                }
              }
            });
          }
        } catch (error) {
          console.log("[link clicks]: JSON parsing error", error);
        }
      }
    };

    eventSource.onerror = (error) => {
      console.log("error", error);
      const dataFetchError: AnalyticsDataFetchError = {
        type: "error",
        error: `${error}}`,
      };
      queryClient.setQueriesData(queryKey, dataFetchError);
    };

    return dataFetchBegin;
  };

  return useQuery<
    AnalyticsDataFetchError | AnalyticsDataFetchBegin | LinkClickStat[]
  >(queryKey, fetchLinkClicks, {
    keepPreviousData: true,
    staleTime: 1000 * 60 * 30, // 30 mins
  });
};
