import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useToast } from "@chakra-ui/react";

import { useAuthToken } from "../../hooks/useAuthToken";
import { SupportUser } from "../../models/support";
import { addNewSupportUsers } from "../../api/support";

interface NewSupportUsers {
  flowId: string;
  // query key to refetch after mutation is completed
  refetchKey: string[];
}

export const useNewSupportUsers = ({ flowId, refetchKey }: NewSupportUsers) => {
  const toast = useToast();
  const queryClient = useQueryClient();
  const { getAuthToken } = useAuthToken();

  return useMutation({
    mutationKey: ["new", "support", "users", flowId],
    mutationFn: async (users: SupportUser[]) => {
      const token = await getAuthToken();

      const result = await addNewSupportUsers({
        flowId,
        token,
        users,
      });

      if (result) {
        toast({ title: "New users added", status: "success" });
      }

      return result;
    },
    // If the mutation fails,
    // use the context returned from onMutate to roll back
    onError: (err, variables, context) => {
      toast({
        description: "failed to add new quick reply",
        status: "error",
      });
    },

    // Always refetch after error or success:
    onSettled: () => {
      if (refetchKey.length !== 0)
        queryClient.invalidateQueries({
          queryKey: refetchKey,
        });
    },
  });
};
