import submissionsAction from "../img/submissions_action.png";
import invitesAction from "../img/invites_action.png";
import analyticsAction from "../img/analytics_action.png";
import campaignAction from "../img/campaign_action.png";
import accountsAction from "../img/account_action.png";
import supportsAction from "../img/support_action.png";

export const showLoginStatus = "show-login";
export const CANDIDATES_LIMIT = 20;

export const arvindCampaignCategories = [
  "Learning and Training",
  "Announcements",
  "Updates about Bot and Policies",
  "Information and Reminders",
];

export const onboardingLabels = [
  "Not Started",
  "PIP Complete",
  "KYC Complete",
  "EMP Complete",
  "Joining Formalities",
  "Induction Complete",
  "Feedback Complete",
  "Onboarding Complete",
];

export const onboardingAnalytics = {
  "Not Started": 6279,
  "PIP Complete": 4396,
  "KYC Complete": 3266,
  "EMP Complete": 2262,
  "Joining Formalities": 1446,
  "Induction Complete": 819,
  "Feedback Complete": 433,
  "Onboarding Complete": 192,
};

export const quessLabels = [
  "Yet to contact",
  "Selected",
  "Rejected",
  "On hold",
];

export const arvindManagers = [
  "rec1@arvind.com",
  "rec2@arvind.com",
  "rec3@arvind.com",
];

export const whAdmins = [
  "mohan@workhack.io",
  "shanti@workhack.io",
  "akshat@edhack.io",
  "siddish@workhack.io",
  "arpit@workhack.io",
  "mv@workhack.io",
];

export const adminUsers = ["main@arvind.com", "admin@arvind.com", ...whAdmins];

export const dateFiltersOptions = {
  "Last Hour": 0,
  Today: 1,
  "This Week": 7,
  "This Month": 30,
};

export const defaultChartOptions = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    datalabels: {
      display: false,
    },
  },
};

export const dashboardActions = {
  invites: {
    title: "let's get more users to experience magic?",
    action: "send new invites",
    gradient: "linear-gradient(180deg, #F44D5E 0%, #D92A93 100%)",
    image: invitesAction,
    redirect: "/newcampaign",
  },
  users: {
    title: "who all are in the flow right now?",
    action: "view submissions",
    gradient: "linear-gradient(180deg, #456ADD 0%, #8651CC 100%);",
    image: submissionsAction,
    redirect: "/users",
  },
  support: {
    title: "take over the mic?",
    action: "message your users",
    gradient: "linear-gradient(229.14deg, #94F533 -2.89%, #2AD0CA 84.74%);",
    image: supportsAction,
    redirect: "/support",
  },
  account: {
    title: "want to nudge your users?",
    action: "send reminders",
    gradient: "linear-gradient(152.26deg, #F74545 0%, #E1F664 101.41%);",
    image: accountsAction,
    redirect: "/account",
  },
  campaigns: {
    title: "did the invites go out?",
    action: "check campaigns",
    gradient: "linear-gradient(152.26deg, #B353FF 0%, #F75D8B 101.41%);",
    image: campaignAction,
    redirect: "/campaigns",
  },
  analytics: {
    title: "are my users actually talking?",
    action: "view analytics",
    gradient: "linear-gradient(180deg, #FF7784 0%, #AD8173 100%);",
    image: analyticsAction,
    redirect: "/analytics",
  },
};

/**
 * @production
 *
 */
export const uploadKeys = {
  Name: `(${[
    "name",
    "candidate",
    "user",
    "applicant",
    "student",
    "employee",
  ].join("|")})`,
  Phonenumber: `(${["number", "phone", "contact", "mobile"].join("|")})`,
  Email: `(${["mail", "email"].join("|")})`,
  Region: `(${["region"].join("|")})`,
  State: `(${["state"].join("|")})`,
  City: `(${["city", "village", "location"].join("|")})`,
  "interview_date": `(${["Interview Date"].join("|")})`,
  "interview_time": `(${["Interview Time"].join("|")})`,
  "interview_address": `(${["Interview Address"].join("|")})`,
  "contact_person": `(${["Contact Person"].join("|")})`,
  "mobile_number": `(${["Mobile No."].join("|")})`,
  "open_text": `(${["Open Text"].join("|")})`,
  "HR1": `(${["hr1"].join("|")})`,
  HR2: `(${["hr2"].join("|")})`,
  "Interview Date": `(${["Interview Date"].join("|")})`,
  "Interview Time": `(${["Interview Time"].join("|")})`,
  "Interview Address": `(${["Interview Address"].join("|")})`,
  "Contact Person": `(${["Contact Person"].join("|")})`,
  "Mobile No.": `(${["Mobile No."].join("|")})`,
  "Open Text": `(${["Open Text"].join("|")})`,
  "Test Assignment": `(${["Test Assignment"].join("|")})`,
};

export const projectAdjectives = [
  "spiritual",
  "amused",
  "obscene",
  "willing",
  "smelly",
  "bright",
  "colossal",
  "abstracted",
  "understood",
  "whispering",
  "ignorant",
  "momentous",
  "black",
  "agonizing",
  "fluffy",
  "regular",
  "drunk",
  "harsh",
  "open",
  "closed",
  "entertaining",
  "jobless",
  "periodic",
  "filthy",
  "sick",
  "nebulous",
  "possible",
  "complex",
  "expensive",
  "fat",
  "psychotic",
  "descriptive",
  "safe",
  "past",
  "nasty",
  "curvy",
  "puny",
  "five",
  "obese",
  "windy",
  "dangerous",
  "lovely",
  "knowledgeable",
  "magical",
  "unique",
  "outrageous",
  "demonic",
  "unhealthy",
  "futuristic",
  "whole",
  "functional",
  "heavy",
  "damaging",
  "silent",
  "thirsty",
  "absurd",
  "tiny",
  "dusty",
  "chemical",
  "married",
  "plastic",
  "juicy",
  "gentle",
  "lethal",
  "general",
  "careful",
  "spiky",
  "super",
  "common",
  "poor",
  "unbiased",
  "exotic",
  "possessive",
  "hilarious",
  "fragile",
  "exciting",
  "deep",
  "helpful",
  "psychedelic",
  "calm",
  "flashy",
  "teeny-tiny",
  "fertile",
  "thankful",
  "fearless",
  "lonely",
  "adorable",
  "wet",
  "crowded",
  "unused",
  "tasteful",
  "unbreakable",
  "breakable",
  "youthful",
  "beautiful",
  "fast",
  "quick",
  "strong",
  "typed",
  "simple",
  "cuddly",
  "sunny",
];

export const projectNouns = [
  "order",
  "butter",
  "ticket",
  "smoke",
  "birds",
  "rock",
  "wing",
  "fog",
  "sky",
  "water",
  "cream",
  "bikes",
  "shoes",
  "vegetable",
  "cow",
  "fork",
  "haircut",
  "waves",
  "dinosaurs",
  "blood",
  "servant",
  "structure",
  "party",
  "clown",
  "pen",
  "throat",
  "frogs",
  "earthq,uake",
  "card",
  "toothpaste",
  "spy",
  "worm",
  "activity",
  "truck",
  "potato",
  "office",
  "island",
  "bottle",
  "fairies",
  "maid",
  "needle",
  "squirrel",
  "cherry",
  "stone",
  "advertisement",
  "bells",
  "kittens",
  "deer",
  "hobbies",
  "river",
  "bubble",
  "soup",
  "cemetery",
  "coal",
  "crime",
  "sugar",
  "thunder",
  "grandfather",
  "ants",
  "steam",
  "aftermath",
  "hair",
  "respect",
  "wound",
  "ground",
  "door",
  "gold",
  "wish",
  "fire",
  "ring",
  "dogs",
  "theory",
  "watch",
  "machine",
  "soda",
  "star",
  "locket",
  "name",
  "berry",
  "castle",
  "reward",
  "pollution",
  "talk",
  "school",
  "reaction",
  "celery",
  "canvas",
  "car",
  "airplane",
  "pet",
  "cakes",
  "grass",
  "breath",
  "hand",
  "snake",
  "dolphin",
  "home",
  "directory",
  "bed",
  "desktop",
  "partner",
  "glass",
  "earth",
  "flower",
  "rose",
  "tree",
  "maze",
  "piano",
  "ball",
  "music",
  "food",
  "shower",
  "window",
  "umbrella",
  "fork",
  "plant",
  "towel",
];

export const basicEmailTemplate = {
  subject: {
    info: "Line 1",
    value: "Your invite is here!",
  },
  body: {
    info: "Line 2",
    value: "You have been shortlisted for a job",
  },
  signature: {
    info: "Line 2",
    value: "Copyright © 2022\nCompany",
  },
};
