import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Button, Container, Image, Stack } from "@chakra-ui/react";
import workhackLogo from "../img/WHLogo.svg";

export const Login = () => {
  const { user, isAuthenticated, loginWithRedirect } = useAuth0();

  useEffect(() => {
    console.log("[Auth] checking login status in login page");

    if (isAuthenticated) {
      console.log(`[Auth] found already login user ${user}`);
    }
  }, []);

  const signIn = async () => await loginWithRedirect({});

  return (
    <Container as={Stack} align="center" h="100vh" justify="center" spacing={8}>
      <Image w="60" src={workhackLogo} />
      <Button colorScheme="pink" onClick={() => signIn()} w="full">
        Login
      </Button>
    </Container>
  );
};
