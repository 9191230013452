import React, { useState, useEffect } from "react";
import {
  Box,
  BoxProps,
  Heading,
  HStack,
  SimpleGrid,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useStoreActions } from "../hooks/store";
import { useNavigate } from "react-router-dom";

import { AddIcon } from "../constants/icons";
import { MicroFlow } from "../models/intent";
import { getMicroFlows } from "../api/flow";
import { useAuthToken } from "../hooks/useAuthToken";
import Progress from "../components/Feedback/Progress";

interface NudgeCardProps extends BoxProps {
  title?: string;
  subtitle?: string;
  icon?: React.ReactNode;
  titleTrailing?: React.ReactNode;
  ref?: React.LegacyRef<HTMLDivElement> | undefined;
}

const NudgeCard = (props: NudgeCardProps) => {
  return (
    <Stack
      px={8}
      py={8}
      spacing={8}
      bg="white"
      boxShadow="md"
      borderWidth="thin"
      borderRadius="md"
      _hover={{
        bg: "pink.50",
        boxShadow: "lg",
        cursor: "pointer",
        borderColor: "pink.100",
        borderWidth: "thin",
      }}
      {...props}
    >
      {props.icon}
      <Box>
        <HStack>
          <Heading size="md">{props.title}</Heading>
          {props.titleTrailing}
        </HStack>
        {props.subtitle && <Text fontSize="md">{props.subtitle}</Text>}
      </Box>
    </Stack>
  );
};

export const Nudge = ({ flowId }: { flowId: string }) => {
  const routeNavigator = useNavigate();
  const { getAuthToken } = useAuthToken();

  const storeCampaignData = useStoreActions(
    (actions) => actions.cacheCampaignData
  );

  const [loading, setLoading] = useState<boolean>(false);
  const [microFlows, setMicroFlows] = useState<{ [name: string]: MicroFlow }>(
    {}
  );

  useEffect(() => {
    const fetchMicroFlows = async () => {
      const token = await getAuthToken();
      try {
        setLoading(true);
        const data = await getMicroFlows({
          flowId,
          token,
        });
        setLoading(false);

        if (data) setMicroFlows(data);
      } catch (error) {
        setLoading(false);
      }
    };

    fetchMicroFlows();
  }, []);

  const startBuildingMicroFlow = (microFlow?: MicroFlow) => {
    if (microFlow) {
      storeCampaignData({ key: "micro_flow", value: microFlow.name });
      return routeNavigator(`/dashboard/${flowId}/new/conversation`, {
        state: {
          flow: microFlow,
        },
      });
    }

    return routeNavigator(`/dashboard/${flowId}/new/conversation`);
  };

  return (
    <Box>
      {loading && <Progress w="full" size="xs" />}
      <Heading>Conversation</Heading>
      <Text fontSize="lg">create or choose from existing</Text>
      <SimpleGrid
        py={4}
        hidden={loading}
        gridGap={4}
        templateColumns="repeat(2, 1fr)"
      >
        <NudgeCard
          title="Blank Canvas"
          subtitle="A Fresh start"
          icon={<AddIcon size={28} />}
          onClick={() => startBuildingMicroFlow()}
        />
        {Object.keys(microFlows).map((name, idx) => (
          <NudgeCard
            title={name}
            onClick={() => startBuildingMicroFlow(microFlows[name])}
          />
        ))}
      </SimpleGrid>
    </Box>
  );
};
