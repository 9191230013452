import React from "react";
import { createRoot } from "react-dom/client";
import { ChakraProvider } from "@chakra-ui/react";
import { Auth0Provider } from "@auth0/auth0-react";
import { StoreProvider } from "easy-peasy";
import {
  createRoutesFromChildren,
  matchRoutes,
  RouterProvider,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import * as Sentry from "@sentry/browser";
import * as SentryProvider from "@sentry/react";

import { store } from "./store";
import { router } from "./router";
import { theme } from "./theme";

import "./theme/style.css";
import "./App.css";

import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";
import { QueryClient } from "@tanstack/react-query";
import { PersistQueryClientProvider } from "@tanstack/react-query-persist-client";
import { createIDBPersister } from "./utils/persister";
import { ErrorBoundary } from "./pages/Error";
TimeAgo.addDefaultLocale(en);

Sentry.init({
  dsn:
    process.env.REACT_APP_SENTRY_URI ||
    "https://a8feb9c00ab84ad0a6d5df393423f324@sentry.workhack.ai/4",
  environment: process.env.REACT_APP_ENVIRONMENT || "Development",
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: SentryProvider.reactRouterV6Instrumentation(
        React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      ),
      tracePropagationTargets: [
        "localhost",
        /.*workhack\.io.*/,
        /.*workhack\.ai.*/,
      ],
    }),
    new Sentry.Replay({
      maskAllText: false,
      maskAllInputs: false,
    }),
  ],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

const container = document.getElementById("root");
const root = createRoot(container);
const queryClient = new QueryClient();
const persister = createIDBPersister();

class App extends React.Component {
  render() {
    return (
      <SentryProvider.ErrorBoundary>
        <ChakraProvider
          theme={theme}
          toastOptions={{ defaultOptions: { position: "bottom-right", duration: 800 } }}
        >
          <Auth0Provider
            audience="https://dashboard-api.workhack.io"
            scope="read:test"
            domain="workhack.us.auth0.com"
            clientId="L9eFT2KfUSYz42Gyw4Wyn2PSYKlI28pp"
            redirectUri={window.location.origin}
            useRefreshTokens
            cacheLocation="localstorage"
          >
            <StoreProvider store={store}>
              <RouterProvider fallbackElement={ErrorBoundary} router={router} />
            </StoreProvider>
          </Auth0Provider>
        </ChakraProvider>
      </SentryProvider.ErrorBoundary>
    );
  }
}

root.render(
  <React.StrictMode>
    <PersistQueryClientProvider
      client={queryClient}
      persistOptions={{ persister }}
    >
      <App />
    </PersistQueryClientProvider>
  </React.StrictMode>
);
