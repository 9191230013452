import { Box, BoxProps, HStack, SimpleGrid, Stack } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getMicroFlows } from "../../../api/flow";
import { AddIcon } from "../../../constants/icons";
import { useStoreActions } from "../../../hooks/store";
import { useAuthToken } from "../../../hooks/useAuthToken";
import { MicroFlow } from "../../../models";
interface NudgeCardProps extends BoxProps {
  active?: boolean;
  title?: string;
  subtitle?: string;
  icon?: React.ReactNode;
  titleTrailing?: React.ReactNode;
  ref?: React.LegacyRef<HTMLDivElement> | undefined;
}

const NudgeCard = (props: NudgeCardProps) => {
  return (
    <HStack
      className={`${
        props.active ? " border-purple-500" : ""
      } cursor-pointer hover:border-purple-500 p-4 mb-2 w-full border-2 rounded-lg justify-between`}
      {...props}
    >
      <Box>
        <p className="text-base font-medium">{props.title}</p>
        {props.subtitle && (
          <p className="text-gray-500 text-sm">{props.subtitle}</p>
        )}
      </Box>
      <Box className="w-fit">{props.icon && <div>{props.icon}</div>}</Box>
    </HStack>
  );
};

const AddMicroFlow = ({ flowId }: { flowId: string }) => {
  const { getAuthToken } = useAuthToken();
  const routeNavigator = useNavigate();

  const [microFlows, setMicroFlows] = useState<{ [name: string]: MicroFlow }>(
    {}
  );

  const storeCampaignData = useStoreActions(
    (actions) => actions.cacheCampaignData
  );

  useEffect(() => {
    const fetchMicroFlows = async () => {
      const token = await getAuthToken();
      const data = await getMicroFlows({
        flowId,
        token,
      });
      if (data) setMicroFlows(data);
    };
    fetchMicroFlows();
  }, []);

  const startBuildingMicroFlow = (microFlow?: MicroFlow) => {
    if (microFlow) {
      storeCampaignData({ key: "micro_flow", value: microFlow.name });
      return routeNavigator(`/dashboard/${flowId}/new/conversation`, {
        state: {
          flow: microFlow,
        },
      });
    }

    return routeNavigator(`/dashboard/${flowId}/new/conversation`);
  };

  return (
    <div className="w-full h-full max-h-full overflow-y-scroll">
      <h1 className="py-2 font-semibold text-lg">📮 Micro Conversation</h1>
      <NudgeCard
        className="w-full bg-white p-4 rounded border border-gray-300 text mb-4 cursor-pointer flex justify-between"
        title="Add Micro conversation"
        subtitle="Start building a fresh microflow"
        icon={
          <AddIcon className="bg-slate-600 rounded-full text-white" size={28} />
        }
        onClick={() => startBuildingMicroFlow()}
      />
      <Stack className="w-full bg-white rounded border border-gray-300 p-4 max-h-[30vh] overflow-y-scroll">
        <p className="font-medium text-base">Saved Micro Conversations</p>
        <SimpleGrid gridGap={4} templateColumns="repeat(2, 1fr)">
          {Object.keys(microFlows).map((name, idx) => (
            <NudgeCard
              className="bg-gray-100 p-3 cursor-pointer rounded-lg"
              title={name}
              onClick={() => startBuildingMicroFlow(microFlows[name])}
            />
          ))}
        </SimpleGrid>
      </Stack>
    </div>
  );
};

export default AddMicroFlow;
