import { extendTheme } from "@chakra-ui/react";
import { mode, StyleFunctionProps } from "@chakra-ui/theme-tools";

export const colorSystem = {
  textPrimary: "#344563",
  textSubdue: "#6B778C",
  textPurple: "#714CD3",
  borderGrey: "#DFDFDF",
  success: "#60CF4A",
  surfaceGrey: "#F5F6F7",
  surfacePurple: "#F7F3FD",
  selectedGrey: "#E2E4E6",
  inputGrey: "#F8F9F9",
  hoverGrey: "#EAEBEC",
  accentPurple: "#E7D9F7",
  backgroundChat: "#FAFBFC",

  // Shadows
  innerShadow: "-6px 0px 20px 0px rgba(0, 0, 0, 0.05) inset",
};

export const theme = extendTheme({
  shadows: { outline: "0 0 0 3px var(--chakra-colors-pink-50)" },
  fonts: {
    heading: "WorkhackFont",
    body: "WorkhackFont",
    mono: "WorkhackMono",
  },
  styles: {
    global: (props: StyleFunctionProps) => ({
      body: {
        bg: mode("#FAFAFA", "#FAFAFA")(props),
      },
      "h1, h2, h3, h4, h5, h6": {
        color: colorSystem["textPrimary"],
      },
    }),
  },
  fontSizes: {
    xs: "0.75rem",
    sm: "0.875rem",
    md: "1rem",
    lg: "1.125rem",
    xl: "1.25rem",
  },
  components: {
    Checkbox: {
      defaultProps: {
        colorScheme: "pink",
      },
    },
    Radio: {
      defaultProps: {
        colorScheme: "pink",
      },
    },
    Progress: {
      defaultProps: {
        colorScheme: "pink",
      },
    },
    Input: {
      defaultProps: {
        fontSize: ".75rem",
        focusBorderColor: "inherit",
        _focus: {
          boxShadow: "md",
        },
      },
    },
    Switch: {
      defaultProps: {
        colorScheme: "pink",
      },
    },
    Textarea: {
      defaultProps: {
        focusBorderColor: "inherit",
        _focus: {
          boxShadow: "md",
        },
      },
    },
    Tooltip: {
      defaultProps: {
        bg: colorSystem["surfaceGrey"],
      },
    },
  },
  colors: {
    pink: {
      50: "#E9D7FE",
      100: "#D6BBFB",
      200: "#B692F6",
      300: "#9E77ED",
      400: "#7F56D9",
      500: "#6941C6",
      600: "#53389E",
      700: "#42307D",
      800: "#42307D",
      900: "#42307D",
    },
    gray: {
      50: "#F9FAFB",
      100: "#F2F4F7",
      200: "#EAECF0",
      300: "#D0D5DD",
      400: "#98A2B3",
      500: "#667085",
      600: "#475467",
      700: "#344054",
      800: "#1D2939",
      900: "#101828",
    },
  },
});

export const whColorsBackgroundLight = "#FEF9FF";
export const whColorsBackgroundDark = "#F6EEF6";
export const whColorsPrimary = "#9d24a9";
export const whColorsBorder = "#E2E8F0";

/** Param Colors */
export const productBackgroundLight = "#F4F5F4";
export const productSelectionBackgroundLight = "#EAECED";
export const productBackgroundNeutral = "#FFFFFF";
export const productSelectionBackgroundNeutral = "#F5F6F7";
export const productSurfaceBackgroundNeutral = "#F1F1F1";
export const productLight = "#F7F3FD";
export const productNeutral = "#714CD3";

export const textPrimary = "#344563";
export const textSecondary = "#6B778C";
