import { useAuthToken } from "../../hooks/useAuthToken";
import axios, { AxiosRequestConfig } from "axios";

interface UseFetchWithAuthProps {
  url: string;
  config?: AxiosRequestConfig;
}

export const useFetchWithAuth = () => {
  const { user, getAuthToken } = useAuthToken();

  const fetchWithAuth = async <T = any>({
    url,
    config = {
      // timeout after 10 seconds by default
      signal: AbortSignal.timeout(1000 * 15),
    },
  }: UseFetchWithAuthProps) => {
    if (!config.headers) config.headers = {};

    const token = await getAuthToken();

    config.headers["Authorization"] = "Bearer " + token;
    config.headers["User-Email"] = user.email;
    config.headers["dashboard-user-id"] = user.email;
    config.headers["user-id"] = user.email;

    try {
      let response = await axios.get<T>(url, config);
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  return fetchWithAuth;
};
