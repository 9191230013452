import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useToast } from "@chakra-ui/react";

import { useAuthToken } from "../../hooks/useAuthToken";
import { SupportUser } from "../../models/support";
import { AssignAgentRequest, assignAgent } from "../../api/support";

interface AgentAssignmentProps {
  flowId: string;
  // query key to refetch after mutation is completed
  refetchKey: string[];
}

export const useAgentAssignment = ({
  flowId,
  refetchKey,
}: AgentAssignmentProps) => {
  const toast = useToast();
  const queryClient = useQueryClient();
  const {
    getAuthToken,
    user: { email: userMail },
  } = useAuthToken();

  return useMutation({
    mutationKey: ["support", "agent_assignment", flowId],
    mutationFn: async ({
      sessionId,
      agentOrTeam,
      onDone,
    }: {
      sessionId: string;
      // agent or team to assign
      agentOrTeam: SupportUser | string;

      onDone?: () => void;
    }) => {
      const token = await getAuthToken();

      const request: AssignAgentRequest = {
        flowId,
        token,
        sessionId,
        userMail,
      };

      if (typeof agentOrTeam === "string") {
        request["team"] = agentOrTeam;
      } else {
        request["agent"] = agentOrTeam;
      }

      const result = await assignAgent(request);

      if (result && result.status === "success") {
        if (result.agent) {
          toast({
            title: `Success`,
            description: `assigned to ${result.agent.email}`,
            status: "success",
            position: "bottom-right",
          });
        }
      }

      onDone?.();
      return result;
    },
    // If the mutation fails,
    // use the context returned from onMutate to roll back
    onError: (err, variables, context) => {
      toast({
        description: "failed to assign query to agent",
        status: "error",
      });
    },

    // Always refetch after error or success:
    onSettled: () => {
      if (refetchKey.length !== 0)
        queryClient.invalidateQueries({
          queryKey: refetchKey,
        });
    },
  });
};
