import React, { forwardRef, useEffect, useState } from "react";
import {
  StackProps,
  Stack,
  HStack,
  FormControl,
  FormErrorMessage,
  Textarea,
  Kbd,
  AspectRatio,
  ButtonGroup,
  Input,
  Text,
  Box,
  Image,
} from "@chakra-ui/react";
import { Formik, FieldArray, Field } from "formik";
import * as Yup from "yup";

import { Fact, getMicroFlows } from "../../../api/flow";
import { FileInputButton } from "../../../components/Input/File";
import { RenderOptions } from "../../../components/Options";
import { Button } from "../../../components/Button";
import {
  CloseIcon,
  AddIcon,
  MediaIcon,
  DeleteIcon,
} from "../../../constants/icons";
import { useAuthToken } from "../../../hooks/useAuthToken";
import { MicroFlow } from "../../../models";
import { useUploadFile } from "../../../mutations/root/useUploadFile";
import { SelectAttributes } from "../../../components/Input";

const AthenaSchema = Yup.object().shape({
  questions: Yup.array()
    .min(1, "At least one question is required")
    .of(Yup.string().min(3, "question must be at least 3 characters").max(1000))
    .required("Required"),
  answer: Yup.string().min(3, "Too Short!").max(1500, "Too Long!").required(""),
  buttons: Yup.array()
    .max(10, "Whatsapp doesn't allow more than 10 options")
    .of(Yup.string())
    .default([]),
});

interface AthenaInputFact {
  questions: string[];
  answer: string;
  buttons?: string[];
}

interface FactInputProps extends StackProps {
  flowId: string;
  fact?: Fact;
  saveTitle?: string;
  onSave?: (fact: Fact) => Promise<boolean>;
  onCancel?: () => void;
}

export const FactInput = forwardRef<HTMLDivElement, FactInputProps>(
  (
    {
      flowId,
      fact = { questions: [""], answer: "" },
      saveTitle = "Save",
      onSave,
      onCancel,
      ...props
    },
    ref
  ) => {
    const uploadFileMutation = useUploadFile();
    const { getAuthToken } = useAuthToken();

    const [answer, setAnswer] = useState("");
    const [selectedImage, setSelectedImage] = useState(
      fact?.remote_media_attachment?.[0]?.link || ""
    );
    const [microFlows, setMicroFlows] = useState<{ [name: string]: MicroFlow }>(
      {}
    );

    const handleFocus = () => {
      document.getElementById("answer").focus();
    };

    useEffect(() => {
      const fetchMicroFlows = async () => {
        const token = await getAuthToken();

        const data = await getMicroFlows({ flowId, token });
        if (data) {
          setMicroFlows(data);
          console.log(data);
        }
      };

      fetchMicroFlows();
    }, []);

    const uploadImage = async (file?: File) => {
      if (!file) return;

      let response = await uploadFileMutation.mutateAsync(file);

      if (response) return setSelectedImage(response.url);

      setSelectedImage(URL.createObjectURL(file));
    };

    return (
      <Formik
        enableReinitialize={true}
        initialValues={{
          questions: fact.questions ?? [""],
          answer: fact.answer ?? "",
          buttons: fact.data?.followup_buttons ?? [],
        }}
        onSubmit={(values: AthenaInputFact, { resetForm }) => {
          let updatedFact: Fact = {
            questions: values.questions,
            answer: values.answer,
            data: {
              followup_buttons: values.buttons ?? [],
            },
          };

          if (selectedImage)
            updatedFact.remote_media_attachment = [
              {
                type: "image",
                link: selectedImage,
              },
            ];
          else updatedFact.remote_media_attachment = [];

          onSave?.(updatedFact).then((result) => {
            if (result) {
              setSelectedImage("");
              resetForm();
            }
          });
        }}
        validationSchema={AthenaSchema}
      >
        {({ values, handleSubmit, handleChange, resetForm }) => (
          <Stack ref={ref} {...props}>
            <FieldArray
              name="questions"
              render={(arrayHelpers) => (
                <>
                  {values.questions &&
                    values.questions.length !== 0 &&
                    values.questions.map((question, index) => (
                      <HStack>
                        <Field name={`questions.${index}`}>
                          {({ field, form }) => (
                            <FormControl
                              isInvalid={
                                form.errors.questions && form.touched.questions
                              }
                            >
                              <Input
                                {...field}
                                variant="outline"
                                placeholder="Eg. What can i eat for breakfast?"
                                maxLength={1000}
                                minLength={3}
                              />
                              <FormErrorMessage>
                                {form.errors.questions &&
                                  form.errors.questions[index]}
                              </FormErrorMessage>
                            </FormControl>
                          )}
                        </Field>
                        <Button
                          hidden={index === 0}
                          onClick={() => arrayHelpers.remove(index)}
                          size="md"
                          variant="tertiary"
                        >
                          <CloseIcon />
                        </Button>
                      </HStack>
                    ))}
                  <Button
                    size="xs"
                    leftIcon={<AddIcon />}
                    alignSelf="start"
                    variant="tertiary"
                    hidden={values.questions.length === 15}
                    onClick={() => arrayHelpers.push("")}
                  >
                    Add alternate Question
                  </Button>
                </>
              )}
            />
            <Field name="answer">
              {({ field, form }) => (
                <FormControl
                  isInvalid={form.errors.answer && form.touched.answer}
                >
                  <Textarea
                    id="answer"
                    {...field}
                    resize="none"
                    placeholder="Anything you like and healthy"
                    maxLength={1500}
                    minLength={3}
                    onChange={(e) => {
                      setAnswer(e.target.value);
                      field.onChange(e);
                      console.log("field", answer);
                      handleFocus();
                    }}
                  />
                  {answer.includes("#") && (
                    <RenderOptions
                      title="Micro Conversations"
                      options={[...Object.keys(microFlows)]}
                      query={answer.slice(answer.indexOf("#") + 1)}
                      onClick={(attribute) => {
                        values.answer =
                          values.answer.substring(
                            0,
                            values.answer.indexOf("#")
                          ) +
                          `goto: ${attribute}_start ; say: do you want me to tell you more about this?`;
                        setAnswer(
                          answer.substring(0, answer.indexOf("#")) +
                            `goto: ${attribute}_start ; say: do you want me to tell you more about this?`
                        );
                        handleFocus();
                      }}
                    />
                  )}
                  {answer.includes("@") && !answer.includes("@ ") ? (
                    <>
                      <SelectAttributes
                        className={`h-[30vh] z-10 absolute rounded-md p-2 bg-white shadow-lg w-2/5 overflow-y-scroll`}
                        flowId={flowId}
                        query={answer.slice(answer.indexOf("@") + 1)}
                        onAttributeSelect={(attribute, isExternal) => {
                          let parsedAnswer =
                            values.answer.substring(
                              0,
                              values.answer.indexOf("@")
                            ) +
                            `{${
                              isExternal ? `attributes.${attribute}` : attribute
                            }}`;

                          values.answer = parsedAnswer;
                          setAnswer(
                            answer.substring(0, answer.indexOf("@")) +
                              `{${
                                isExternal
                                  ? `attributes.${attribute}`
                                  : attribute
                              }}`
                          );
                          handleFocus();
                        }}
                        title="Choose attribute"
                      />
                    </>
                  ) : values.answer.includes("@ ") ? (
                    <></>
                  ) : (
                    <></>
                  )}
                  <FormErrorMessage>{form.errors.answer}</FormErrorMessage>
                  <Text py={1} fontSize="xs">
                    TIP: <Kbd>@</Kbd> for attributes <Kbd>#</Kbd> for micro
                    conversations
                  </Text>
                </FormControl>
              )}
            </Field>
            <FieldArray
              name="buttons"
              render={(arrayHelpers) => (
                <>
                  {values.buttons &&
                    values.buttons.length !== 0 &&
                    values.buttons.map((button, index) => (
                      <HStack>
                        <Field name={`buttons.${index}`}>
                          {({ field, form }) => (
                            <FormControl
                              isInvalid={
                                form.errors.buttons && form.touched.buttons
                              }
                            >
                              <Input
                                {...field}
                                variant="outline"
                                placeholder="Eg. What can i eat for breakfast?"
                                maxLength={1000}
                                minLength={3}
                              />
                              <FormErrorMessage>
                                {form.errors.buttons &&
                                  form.errors.buttons[index]}
                              </FormErrorMessage>
                            </FormControl>
                          )}
                        </Field>
                        <Button
                          onClick={() => arrayHelpers.remove(index)}
                          size="md"
                          variant="tertiary"
                        >
                          <CloseIcon />
                        </Button>
                      </HStack>
                    ))}
                  <Button
                    size="xs"
                    leftIcon={<AddIcon />}
                    alignSelf="start"
                    variant="tertiary"
                    onClick={() => arrayHelpers.push("")}
                  >
                    Add action button
                  </Button>
                </>
              )}
            />
            <Box borderRadius="sm" pos="relative" hidden={selectedImage === ""}>
              <AspectRatio ratio={18 / 9}>
                <Image src={selectedImage} />
              </AspectRatio>
              <Button
                variant="tertiary"
                leftIcon={<DeleteIcon />}
                position="absolute"
                top={5}
                right={5}
                onClick={() => setSelectedImage("")}
              >
                Remove Image
              </Button>
            </Box>
            <HStack justify="space-between">
              <FileInputButton
                isLoading={uploadFileMutation.isLoading}
                onFileUpload={async (file) => uploadImage(file)}
                variant="tertiary"
                leftIcon={<MediaIcon />}
              >
                Attach Image
              </FileInputButton>

              <ButtonGroup>
                <Button onClick={() => handleSubmit()}>{saveTitle}</Button>
                <Button
                  onClick={() => {
                    onCancel?.();
                    resetForm();
                    setSelectedImage("");
                  }}
                  variant="tertiary"
                >
                  Cancel
                </Button>
              </ButtonGroup>
            </HStack>
          </Stack>
        )}
      </Formik>
    );
  }
);
